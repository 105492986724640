import { useEffect } from "react";
var useIHeartTracking = function(param) {
    var action = param.action;
    useEffect(function() {
        var pixel = document.createElement("img");
        var params = new URLSearchParams();
        params.append("ad_log", "referer");
        if (action) {
            params.append("action", action);
        }
        params.append("pixid", "dfd0fbd9-e7bd-4f9b-83ca-25f7bee979d8");
        pixel.src = "https://arttrk.com/pixel/?".concat(params.toString());
        pixel.width = 1;
        pixel.height = 1;
        pixel.style.position = "fixed";
        pixel.alt = "";
        document.body.appendChild(pixel);
        return function() {
            document.body.removeChild(pixel);
        };
    }, [
        action
    ]);
    return null;
};
export default useIHeartTracking;
