import { NextPage } from 'next';
import HomePage from '@sy/app-client/src/ssrAdapter/pages/HomePage';

import MarketingTemplate from '~/components/templates/MarketingTemplate';
import buildMetatadata from '~/utils/buildMetadata';
import { getMarketingNavigation } from '~/utils/getNavigation';
import useIHeartTracking from '@sy/app-client/src/ssrAdapter/components/useIHeartTracking';
import { buildGetServerSideProps } from '../getServerSideProps';
import type { CommonContext, InferProps } from '../getServerSideProps';

const Home: NextPage<InferProps<typeof getServerSideProps>> = ({
	featureFlags,
	navigation,
	user,
}) => {
	useIHeartTracking({
		action: 'lead',
	});
	return (
		<MarketingTemplate
			featureFlags={featureFlags}
			navigation={navigation}
			user={user}
		>
			<HomePage />
		</MarketingTemplate>
	);
};

const customServerSideProps = async (context: CommonContext) => {
	const { locale, featureFlags, query, requestUrl } = context;
	const { origin, pathname } = requestUrl;

	return {
		props: {
			metadata: buildMetatadata({ canonical: `${origin}${pathname}` }),
			navigation: await getMarketingNavigation({ featureFlags, locale, query }),
		},
	};
};

export const getServerSideProps = buildGetServerSideProps({
	customServerSideProps,
	translationNamespaces: [
		'common',
		'landingMainPage',
		'landing',
		'auth',
		'pricing',
	],
	redirect: {
		anonOnly: true,
		redirectTo: '/broadcasts',
		propagateQueryParameters: true,
	},
});

export default Home;
